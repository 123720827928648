import React, { useEffect, useCallback, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Table from "../../../../components/organisms/Table"
import IDTooltip from "../../../../components/molecules/IDTooltip/IDTooltip"

// Hooks
import useAccommodationsApi from "../../../../hooks/Api/useAccommodationsApi"
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionsState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

import style from "./AccommodationsList.module.css"

const permissions = [
  PERMISSIONS.PERM_ACCOMMODATIONS_DISPLAY,
  PERMISSIONS.PERM_ACCOMMODATIONS_CREATE,
  PERMISSIONS.PERM_ACCOMMODATIONS_ARCHIVE,
  PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE,
  PERMISSIONS.PERM_ACCOMMODATION_PRICES_UPDATE,
]

const AccommodationsList = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionsState(userInfo, permissions)

  const [
    accommodationsRequestState,
    accommodationsData,
    accommodationsError,
    { getAccommodations },
  ] = useAccommodationsApi({
    trigger: true,
  })
  const savedGetAccommodations = useCallback(getAccommodations, [])
  const { state } = useLocation()
  const [displayArchived, setDisplayArchived] = useState(false)

  useEffect(() => {
    savedGetAccommodations()
  }, [savedGetAccommodations])

  const accommodationsCollectionToArray = () =>
    accommodationsData && accommodationsData.accommodations
      ? Object.keys(accommodationsData.accommodations).map(
          i => accommodationsData.accommodations[i]
        )
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <div className="d-inline-flex">
          <h1>Produits / Hébergements</h1>
          {displayArchived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Hébergements archivés
              </span>
            </h3>
          ) : null}
        </div>
        {rolePermissions[PERMISSIONS.PERM_ACCOMMODATIONS_CREATE] ? (
          <Link className="btn btn-success" to="/accommodations/edit">
            Nouvel hébergement
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {accommodationsError ? (
        <div className="alert alert-accommodationsError" role="alert">
          {accommodationsError}
        </div>
      ) : null}
      {accommodationsRequestState === "loading" && !accommodationsData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_ACCOMMODATIONS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_ACCOMMODATIONS_UPDATE] ? (
            <>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="display-archived"
                  checked={displayArchived}
                  onClick={() => setDisplayArchived(!displayArchived)}
                />
                <label className="form-check-label" htmlFor="display-archived">
                  Afficher les hébergements archivés
                </label>
              </div>
              <Table
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                  },
                  {
                    Header: "Id Produit",
                    accessor: "product",
                    Cell: ({
                      cell: {
                        row: {
                          values: { product },
                        },
                      },
                    }) =>
                      product ? <IDTooltip uid={product.id} /> : <span>?</span>,
                  },
                  {
                    Header: "Nom",
                    accessor: "product.name.1",
                  },
                  {
                    Header: "",
                    accessor: "tag",
                    Cell: ({
                      cell: {
                        row: {
                          values: { id, product },
                        },
                      },
                    }) => (
                      <>
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="Edit accommodation"
                        >
                          <Link
                            className="btn btn-secondary"
                            to={`/accommodations/edit/${id}`}
                          >
                            Éditer
                          </Link>
                        </div>
                        {rolePermissions[
                          PERMISSIONS.PERM_ACCOMMODATION_PRICES_UPDATE
                        ] ? (
                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Edit camp"
                          >
                            <Link
                              className="btn btn-secondary ml-3"
                              to={`/prices/edit/${product.id}`}
                            >
                              Prix
                            </Link>
                          </div>
                        ) : null}
                      </>
                    ),
                  },
                ]}
                data={accommodationsCollectionToArray().filter(
                  accommodation => {
                    if (!accommodation.product) return false
                    if (displayArchived) {
                      if (accommodation.product.archived) return true
                      return false
                    }
                    if (accommodation.product.archived) return false
                    return true
                  }
                )}
                hasPagination
                hasSort
                hasSearch
              />
            </>
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default AccommodationsList
