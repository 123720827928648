/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useCallback, useContext } from "react"
import { Route, Switch } from "react-router-dom"
import jwtDecode from "jwt-decode"
import { KeycloakAuthContext } from "./components/templates/KeycloakAuthProvider"

// Components
import Login from "./views/legacy/Login"

// Hooks
import useAppContext from "./hooks/useAppContext"

// Routes
import { protectedRoutes } from "./views/legacy/routes"

const Routes = () => {
  const { keycloak } = useContext(KeycloakAuthContext)
  const { setUserInfo } = useAppContext()
  
  const savedSetUserInfo = useCallback(setUserInfo, [])

  useEffect(() => {
    if (keycloak?.token) {
      const decoded = jwtDecode(keycloak.token)
      setTimeout(() => {
        savedSetUserInfo(decoded)
      }, 1000)
    }
  }, [keycloak?.token, savedSetUserInfo])

  return (
    <Switch>
      {keycloak.authenticated
        ? protectedRoutes.map(route => <Route key={route.path} {...route} />)
        : null}

      <Route path="/" component={Login} />
    </Switch>
  )
}
export default Routes
