import React, { useEffect, useCallback, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Components
import WithMenu from "../../../../components/templates/WithMenu/WithMenu"
import Table from "../../../../components/organisms/Table"
import IDTooltip from "../../../../components/molecules/IDTooltip/IDTooltip"

// Hooks
import useExtrasApi from "../../../../hooks/Api/useExtrasApi"
import useAppContext from "../../../../hooks/useAppContext"
import usePermissionState from "../../../../hooks/usePermissionsState"

// Constants
import { PERMISSIONS } from "../../../../constants/constants"

import style from "./ExtrasList.module.css"

const permissions = [
  PERMISSIONS.PERM_EXTRAS_DISPLAY,
  PERMISSIONS.PERM_EXTRAS_CREATE,
  PERMISSIONS.PERM_EXTRAS_ARCHIVE,
  PERMISSIONS.PERM_EXTRAS_UPDATE,
  PERMISSIONS.PERM_EXTRA_PRICES_UPDATE,
]

const ExtrasList = () => {
  const {
    context: { userInfo },
  } = useAppContext()
  const rolePermissions = usePermissionState(userInfo, permissions)

  const [
    extrasRequestState,
    extrasData,
    extrasError,
    { getExtras },
  ] = useExtrasApi({
    trigger: true,
  })
  const savedGetExtras = useCallback(getExtras, [])
  const { state } = useLocation()
  const [displayArchived, setDisplayArchived] = useState(false)

  useEffect(() => {
    savedGetExtras()
  }, [savedGetExtras])

  const extrasCollectionToArray = () =>
    extrasData && extrasData.extras
      ? Object.keys(extrasData.extras).map(i => extrasData.extras[i])
      : []

  return (
    <WithMenu>
      <div className={style.pageHeader}>
        <div className="d-inline-flex">
          <h1>Produits / Extras</h1>
          {displayArchived ? (
            <h3 className="ml-4">
              <span className="mt-2 badge badge-warning">
                <i className="fas fa-exclamation-triangle mr-2" />
                Extras archivés
              </span>
            </h3>
          ) : null}
        </div>
        {rolePermissions[PERMISSIONS.PERM_EXTRAS_CREATE] ? (
          <Link className="btn btn-success" to="/extra/edit">
            Nouvel Extra
          </Link>
        ) : null}
      </div>
      {state && state.success && (
        <div className="alert alert-success" role="alert">
          Sauvegardé avec succès
        </div>
      )}
      {extrasError ? (
        <div className="alert alert-extrasError" role="alert">
          {extrasError}
        </div>
      ) : null}
      {extrasRequestState === "loading" && !extrasData ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {rolePermissions[PERMISSIONS.PERM_EXTRAS_DISPLAY] ||
          rolePermissions[PERMISSIONS.PERM_EXTRAS_UPDATE] ? (
            <>
              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="display-archived"
                  checked={displayArchived}
                  onClick={() => setDisplayArchived(!displayArchived)}
                />
                <label className="form-check-label" htmlFor="display-archived">
                  Afficher les extras archivés
                </label>
              </div>
              <Table
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                  },
                  {
                    Header: "Id Produit",
                    accessor: "product",
                    Cell: ({
                      cell: {
                        row: {
                          values: { product },
                        },
                      },
                    }) =>
                      product ? <IDTooltip uid={product.id} /> : <span>?</span>,
                  },
                  {
                    Header: "Nom",
                    accessor: "product.name.1",
                  },
                  {
                    Header: "",
                    accessor: "tag",
                    Cell: ({
                      cell: {
                        row: {
                          values: { id, product },
                        },
                      },
                    }) => (
                      <>
                        <div
                          className="btn-group btn-group-sm"
                          role="group"
                          aria-label="Edit extra"
                        >
                          <Link
                            className="btn btn-secondary"
                            to={`/extra/edit/${id}`}
                          >
                            Éditer
                          </Link>
                        </div>
                        {rolePermissions[
                          PERMISSIONS.PERM_EXTRA_PRICES_UPDATE
                        ] ? (
                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Edit camp"
                          >
                            <Link
                              className="btn btn-secondary ml-3"
                              to={`/prices/edit/${product.id}`}
                            >
                              Prix
                            </Link>
                          </div>
                        ) : null}
                      </>
                    ),
                  },
                ]}
                data={extrasCollectionToArray().filter(extras => {
                  if (!extras.product) return false
                  if (displayArchived) {
                    if (extras.product.archived) return true
                    return false
                  }
                  if (extras.product.archived) return false
                  return true
                })}
                hasPagination
                hasSort
                hasSearch
              />
            </>
          ) : (
            <span>Vous n'avez pas les permissions d'afficher ces données</span>
          )}
        </>
      )}
    </WithMenu>
  )
}
export default ExtrasList
